import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import  Firebaseui  from 'firebaseui'

// Your web app's Firebase configuration

var firebaseConfig = {
  apiKey: "AIzaSyBQac5PZqqfD1BO21iRLUASoemsJGBxBH0",
  authDomain: "spried-bb41e.firebaseapp.com",
  projectId: "spried-bb41e",
  storageBucket: "spried-bb41e.appspot.com",
  messagingSenderId: "559502217297",
  appId: "1:559502217297:web:d04d1db49ddbb3cec6a870",
  measurementId: "G-R5X1NVP6ER"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth;

// eslint-disable-next-line no-restricted-globals
if(process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development")
{
  db.useEmulator('localhost', 8080);
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default firebase;
export { db, auth };